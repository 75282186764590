
import { defineComponent } from "vue-demi";
import { Course } from "@/interfaces/dto/course";

export default defineComponent({
  name: "ProfileCourses",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      courses: [] as Array<Course.Dto>,
    };
  },
  created() {
    this.getCourses();
  },
  methods: {
    async getCourses() {
      const result = await this.API.common.getCourses();
      this.courses = result.data;
    },
    async changeCourse(course: string) {
      const result = await this.API.common.changeCourse({ course });
      this.$store.commit("updateUser", result.data);
    },
  },
});
